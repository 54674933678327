import axios from "axios";
import { AuthenticationGuard } from "../features/authentication-guard/authentication-guard.container";
import {
  GetVisitsForSpecificMonthRequestPayload,
  GetVisitsWithoutRequireTruckForSpecificMonthRequestPayload,
} from "./interfaces/booking-board.interfaces";
import {
  CompanyLocationVisit,
  FindVisits,
  AppointmentData,
  VisitWithTimeslotsData,
  VisitData,
} from "src/interfaces/visit.interface";
import { Patient } from "./types";

axios.defaults.withCredentials = true;

export class ApiClient {
  public static readonly url = process.env.API_URL;

  public static async getAccountsMe() {
    return this.requestProxy(
      axios.get(`${this.url}/authorization/accounts/me`, {
        headers: this.headers,
      })
    );
  }

  // FIXME: strict types, shared dtos?
  public static createCompany(payload: any) {
    return this.requestProxy(
      axios.post(`${this.url}/booking/companies`, payload, {
        headers: this.headers,
      })
    );
  }

  /**
   * Fetch all data from the given resource
   * @returns Axios.Response
   */
  private static async getAllResources<T>(url: string, params: any, headers: any = {}, options?: {limit?: number }): Promise<{data: {data: T[], total: number, limit: number, offset: number}}> {
    const allData = [];
    let offset = 0;
    let limit = options?.limit || 300;
    let response: { data?: {data: any[], total?: number, limit?: number, offset?: number}};
    do {
      response = await this.requestProxy(
        axios.get(url, {
          params: {
            ...params,
            offset,
            limit,
          },
          headers,
        })
      );

      if (!response.data) {
        console.log(`ERROR: Fetch all resources failed. Response data is empty. [url: ${url}]`);
        break;
      }

      allData.push(...response.data?.data || []);
      offset += limit;

    } while ((response?.data?.total || 0) > offset);

    return {data: { data: allData, total: (response?.data?.total||0), limit, offset: 0}}
  }

  public static async findCompanies(payload: {[key: string]: unknown, limit: number | "all"}) {
    if (payload?.limit === "all") {
      return this.getAllResources(`${this.url}/booking/companies`, payload, this.headers)
    }
    return this.requestProxy(
      axios.get(`${this.url}/booking/companies`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static getCompany(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/companies/${id}`, {
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static updateCompany(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/companies/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static activateCompany(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/companies/${id}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static deactivateCompany(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/companies/${id}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static createLocation(id: string, payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/companies/${id}/locations`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static updateLocation(id: string, locationId: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/companies/${id}/locations/${locationId}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getLocations(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/companies/locations`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static createPatient(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/patients`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static createLifeSciencePatient(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/patients/life-sciences`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static createSchoolPatient(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/patients/schools`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static updatePatient(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/patients/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateLifeSciencePatient(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/patients/life-sciences/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateSchoolPatient(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/patients/schools/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static findPatients(payload: {[key: string]: unknown, limit: number | "all"}) {
    if (payload?.limit === "all") {
      return this.getAllResources<Patient>(`${this.url}/booking/patients`, payload, this.headers)
    }
    return this.requestProxy(
      axios.get(`${this.url}/booking/patients`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static findAccounts(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/authorization/find-accounts`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static getPatient(id: string) {
    return this.requestProxy(
      axios.get<Patient>(`${this.url}/booking/patients/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static syncPatientToAcuitas(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/patients/${id}/sync-to-acuitas`, {
        headers: this.headers,
      })
    );
  }

  public static matchPatientWithAccount(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/patients/${id}/match`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static findTrucks() {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks`, {
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static createTruck(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static updateTruck(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/trucks/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static activateTruck(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks/${id}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static deactivateTruck(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks/${id}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static getTruck(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks/${id}`, {
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static getTruckAvailability(id: string, payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks/${id}/availability`, {
        params: {
          ...payload,
        },
        headers: this.headers,
      })
    );
  }

  public static getTruckAvailabilityForActiveTrucks(payload: { date: string }) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks/availability`, {
        params: {
          ...payload,
        },
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static createVisit(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getBookingBoardVisitsForSpecificMonth(payload: GetVisitsForSpecificMonthRequestPayload) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/booking-board/month`, {
        headers: this.headers,
        params: {
          ...payload,
        },
      })
    );
  }

  public static getBookingBoardVisitsMetricsForSpecificMonth(payload: GetVisitsForSpecificMonthRequestPayload) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/booking-board/month/metrics`, {
        headers: this.headers,
        params: {
          ...payload,
        },
      })
    );
  }

  public static getBookingBoardVisitsWithoutRequireTruckMetricsForSpecificMonth(
    payload: GetVisitsWithoutRequireTruckForSpecificMonthRequestPayload
  ) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/booking-board/month/without-require-truck`, {
        headers: this.headers,
        params: {
          ...payload,
        },
      })
    );
  }

  public static findVisits(payload: {[key: string]: unknown, limit: number | "all"}): Promise<{ data: FindVisits }> {
    if (payload?.limit === "all") {
      return this.getAllResources<VisitData>(`${this.url}/booking/visits`, payload, this.headers)
    }
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits`, {
        headers: this.headers,
        params: {
          ...payload,
        },
      })
    );
  }

  public static getVisit(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static getExcludedDatesForSpecificMonth(month: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/excluded-dates/${month}`, {
        headers: this.headers,
      })
    );
  }

  public static getExcludedTrucksForSpecificMonth(month: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks/excluded`, {
        headers: this.headers,
        params: { month },
      })
    );
  }

  public static getExcludedTrucksForSpecificDate(date: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/trucks/excluded`, {
        headers: this.headers,
        params: { date },
      })
    );
  }

  public static updateExcludedDatesForSpecificMonth(month: string, dates: string[]) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/visits/excluded-dates/${month}`,
        {
          dates,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateExcludedTrucks(date: string, truckIds: string[]) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks/excluded`,
        {
          date,
          truckIds,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getVisitTimeslots(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/${id}/timeslots`, {
        headers: this.headers,
      })
    );
  }

  public static activateVisit(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${id}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static deactivateVisit(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${id}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateVisit(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/visits/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static changeVisitStatus(id: string, status: string) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/visits/${id}/status`,
        { status },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateVisitSchedulingNotes(id: string, payload: { schedulingNotes: string }) {
    return this.requestProxy(
      axios.patch(`${this.url}/booking/visits/${id}/scheduling-notes`, payload, {
        headers: this.headers,
      })
    );
  }

  public static getVisitSchedulingNotes(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/${id}/scheduling-notes`, {
        headers: this.headers,
      })
    );
  }

  public static getVisitStaff(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/${id}/staff`, {
        headers: this.headers,
      })
    );
  }

  public static getEmployees() {
    return this.requestProxy(
      axios.get(`${this.url}/authorization/accounts-with-positions`, {
        headers: this.headers,
      })
    );
  }

  public static addEmployeeToVisit(id: string, body: { id: string; position: string }) {
    return this.requestProxy(
      axios.post(`${this.url}/booking/visits/${id}/employees`, body, {
        headers: this.headers,
      })
    );
  }

  public static removeEmployeeFromVisit(id: string, employeeId: string) {
    return this.requestProxy(
      axios.delete(`${this.url}/booking/visits/${id}/employees/${employeeId}`, {
        headers: this.headers,
      })
    );
  }

  public static getVisitTimeRange(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/${id}/time-range`, {
        headers: this.headers,
      })
    );
  }

  public static synchronizeSalesforceVisit(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/salesforce/synchronizer/visit/${id}`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getCompanyByLocationId(locationId: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/companies/by-location/${locationId}`, {
        headers: this.headers,
      })
    );
  }

  public static removeTruckReservation(truckId: string, visitId: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks/${truckId}/remove-reservation`,
        {
          visitId,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static reserveTruck(truckId: string, visitId: string, date: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/trucks/${truckId}/reserve`,
        {
          visitId,
          date,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getTimeslotTemplates() {
    return this.requestProxy(
      axios.get(`${this.url}/booking/timeslot-templates`, {
        headers: this.headers,
      })
    );
  }

  public static createTimeslotTemplate(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/timeslot-templates`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateTimeslotTemplate(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/timeslot-templates/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static removeTimeslotTemplate(id: string) {
    return this.requestProxy(
      axios.delete(`${this.url}/booking/timeslot-templates/${id}`, {
        headers: this.headers,
      })
    );
  }

  /***********************************************/
  // Single timeslot manipulation

  public static addSingleTimeslot(visitId: string, payload: { startDate: Date; endDate: Date }) {
    return this.requestProxy(
      axios.post<void>(`${this.url}/booking/visits/${visitId}/timeslots/add`, payload, {
        headers: this.headers,
      })
    );
  }

  public static removeSingleTimeslot(visitId: string, timeslotId: string) {
    return this.requestProxy(
      axios.delete<void>(`${this.url}/booking/visits/${visitId}/timeslots/${timeslotId}`, {
        headers: this.headers,
      })
    );
  }

  public static activateSingleTimeslot(visitId: string, timeslotId: string) {
    return this.requestProxy(
      axios.patch<void>(
        `${this.url}/booking/visits/${visitId}/timeslots/${timeslotId}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static deactivateSingleTimeslot(visitId: string, timeslotId: string) {
    return this.requestProxy(
      axios.patch<void>(
        `${this.url}/booking/visits/${visitId}/timeslots/${timeslotId}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  /***********************************************/

  public static addTimeslots(id: string, payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${id}/timeslots`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getInsurance(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/insurances/${id}`, {
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static findInsurances() {
    return this.requestProxy(
      axios.get(`${this.url}/booking/insurances`, {
        headers: this.headers,
      })
    );
  }

  // FIXME:
  public static createInsurance(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/insurances`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static updateInsurance(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/insurances/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static activateInsurance(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/insurances/${id}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static deactivateInsurance(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/insurances/${id}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static setInsuranceHidden(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/insurances/${id}/set-insurance-hidden`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static unsetInsuranceHidden(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/insurances/${id}/unset-insurance-hidden`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  // FIXME:
  public static findNPSResponses(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/nps/responses`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static generateNPSResponsesCSVFile(payload: {[key: string]: unknown, limit: number | "all"}) {
    if (payload?.limit === "all") {
      return this.getAllResources(`${this.url}/nps/responses/csv`, payload, this.headers, {limit: 1000})
    }
    return this.requestProxy(
      axios.get(`${this.url}/nps/responses/csv`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static findWaitlistSubscriptions(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/waitlist/subscriptions`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static createWaitlistSubscription(payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/waitlist/subscriptions`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getWaitlistSubscription(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/waitlist/subscriptions/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static updateWaitlistSubscription(id: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/waitlist/subscriptions/${id}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static deleteWaitlistSubscription(id: string) {
    return this.requestProxy(
      axios.delete(`${this.url}/waitlist/subscriptions/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static findMessages(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/communication-bus/messages`, {
        params: {
          ...payload,
        },
        headers: this.headers,
      })
    );
  }

  public static cancelMessage(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/communication-bus/messages/${id}/cancel`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static forceSendMessage(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/communication-bus/messages/${id}/force-send`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getTemplates(payload: {[key: string]: unknown, limit: number | "all"} ) {
    if (payload?.limit === "all") {
      return this.getAllResources(`${this.url}/communication-bus/templates`, payload, this.headers)
    }
    return this.requestProxy(
      axios.get(`${this.url}/communication-bus/templates`, {
        headers: this.headers,
        params: payload,
      })
    );
  }

  public static getTemplate(name: string) {
    return this.requestProxy(
      axios.get(`${this.url}/communication-bus/templates/${name}`, {
        headers: this.headers,
      })
    );
  }

  public static getMandrillTemplates() {
    return this.requestProxy(
      axios.get(`${this.url}/communication-bus/mandrill-templates`, {
        headers: this.headers,
      })
    );
  }

  public static updateTemplate(name: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/communication-bus/templates/${name}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static activateTemplate(name: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/communication-bus/templates/${name}/activate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static deactivateTemplate(name: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/communication-bus/templates/${name}/deactivate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static findMigrationJobs() {
    return this.requestProxy(
      axios.get(`${this.url}/booking/migration-jobs`, {
        headers: this.headers,
      })
    );
  }

  public static getMigrationJob(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/migration-jobs/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static resolveMigrationJob(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/migration-jobs/${id}/resolve`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static unresolveMigrationJob(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/migration-jobs/${id}/unresolve`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getAppointment(id: string): Promise<{ data: AppointmentData }> {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/appointments/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static findAppointments(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/appointments`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static findDocumentsInfo(payload: {
    offset: number;
    limit: number;
    appointmentId?: string;
    patientId?: string;
    status?: "found" | "not_found";
  }) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/documents/acuitas/info`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static recheckAcuitasDocuments(id: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/documents/acuitas/info/${id}/recheck`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static generateCSVFileWithAppointments(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/appointments/csv`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static createAppointment(visitId: string, payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${visitId}/appointments`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static createRetailOnlyAppointment(visitId: string, payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${visitId}/appointments/retail-only`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static updateAppointment(visitId: string, appointmentId: string, payload: any) {
    return this.requestProxy(
      axios.patch(
        `${this.url}/booking/visits/${visitId}/appointments/${appointmentId}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static rescheduleAppointment(
    visitId: string,
    appointmentId: string,
    payload: {
      timeslotId: string;
      targetVisitId: string;
      targetTimeslotId: string;
    }
  ) {
    return this.requestProxy(
      axios.post<void>(
        `${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/reschedule`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static cancelAppointment(visitId: string, appointmentId: string) {
    return this.requestProxy(
      axios.post<void>(
        `${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/cancel_by_admin`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getStatsVisits(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/stats`, {
        headers: this.headers,
        params: {
          ...payload,
        },
      })
    );
  }

  public static getVisitStats(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/stats/visit/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static getCSVWithStatsForVisit(visitIds: string[]) {
    return this.requestProxy(
      axios.get(`${this.url}/stats/visits/csv`, {
        headers: this.headers,
        params: {
          visitIds,
        },
      })
    );
  }

  public static getUnbounceStats(companySlug: string) {
    return this.requestProxy(
      axios.get(`${this.url}/stats/unbounce`, {
        headers: this.headers,
        params: {
          companySlug,
        },
      })
    );
  }

  public static getVisitConversion(id: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/conversions/${id}`, {
        headers: this.headers,
      })
    );
  }

  public static updateProducts(visitId: string, appointmentId: string, payload: any) {
    return this.requestProxy(
      axios.patch(`${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/products`, payload, {
        headers: this.headers,
      })
    );
  }

  public static createProducts(visitId: string, appointmentId: string, payload: any) {
    return this.requestProxy(
      axios.post(`${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/products`, payload, {
        headers: this.headers,
      })
    );
  }

  public static removeProduct(visitId: string, appointmentId: string, productId: string) {
    return this.requestProxy(
      axios.delete(`${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/products/${productId}`, {
        headers: this.headers,
      })
    );
  }

  public static uploadFile(payload: any) {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]: any) => {
      formData.append(key, value);
    });

    return this.requestProxy(
      axios.post(`${this.url}/storage/files`, formData, {
        headers: {
          ...this.headers,
          "Content-Type": "multipart/form-data",
        },
      })
    );
  }

  public static getFiles(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/storage/files`, {
        params: {
          ...payload,
        },
        headers: this.headers,
      })
    );
  }

  public static removeFile(fileId: string) {
    return this.requestProxy(
      axios.delete(`${this.url}/storage/files/${fileId}`, {
        headers: this.headers,
      })
    );
  }

  public static sendFiles(visitId: string, appointmentId: string, fileIds: string[]) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/visits/${visitId}/appointments/${appointmentId}/send-documents`,
        {
          fileIds,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static resetFailedLoginAttempts(accountId: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/authorization/accounts/${accountId}/reset-failed-login-attempts`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static createQuestionnaire(patientId: string, payload: any) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/questionnaires/${patientId}`,
        {
          ...payload,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  public static syncQuestionnaireToAcuitas(patientId: string) {
    return this.requestProxy(
      axios.post(
        `${this.url}/booking/questionnaires/${patientId}/sync-to-acuitas`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public static getQuestionnaire(patientId: string) {
    return this.requestProxy(
      axios.get(`${this.url}/booking/questionnaires/${patientId}`, {
        headers: this.headers,
      })
    );
  }

  public static getPreviousAppointments(patientId: string) {
    return axios.get(`${this.url}/booking/visits/appointments/patient/${patientId}/previous`, {
      headers: this.headers,
    });
  }

  public static getDocuments(patientId: string) {
    return axios.get(`${this.url}/booking/documents/patient/${patientId}`, {
      headers: this.headers,
    });
  }

  public static findFutureVisitsAccordingToCoordinates(payload: any): Promise<{ data: CompanyLocationVisit[] }> {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/find-future-visits-according-to-coordinates`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static findOpenTimeslotsInVisits(payload: any): Promise<{ data: VisitWithTimeslotsData[] }> {
    return this.requestProxy(
      axios.get(`${this.url}/booking/visits/find-open-timeslots`, {
        params: payload,
        headers: this.headers,
      })
    );
  }

  public static findAccountsByPositions(payload: any) {
    return this.requestProxy(
      axios.get(`${this.url}/authorization/find-accounts-by-positions`, {
        params: payload,
      })
    );
  }

  public static async sendUpdateLocationEmail(visitId: string) {
    await axios.post(`${this.url}/booking/visits/${visitId}/send-update-location-email`, {
      headers: this.headers,
    });
  }

  public static async findCompaniesWithLocations(payload: { limit: number | "all"; offset: number }) {
    if (payload.limit === "all") {
      return this.getAllResources(`${this.url}/booking/companies/with-locations`, {}, this.headers)
    }
    return this.requestProxy(
      axios.get(`${this.url}/booking/companies/with-locations`, {
        params: {
          limit: payload.limit,
          offset: payload.offset,
        },
      })
    );
  }

  public static changePassword(payload: { currentPassword: string; newPassword: string }) {
    return this.requestProxy(
      axios.patch(`${this.url}/authorization/accounts/me/change-password`, payload, {
        headers: this.headers,
      })
    );
  }

  public static changeTimeslotLength(visitId: string, payload: { timeslotLength: number }) {
    return this.requestProxy(
      axios.put(`${this.url}/booking/visits/${visitId}/timeslot-length`, payload, {
        headers: this.headers,
      })
    );
  }

  public static async requestProxy<T>(request: Promise<T>) {
    try {
      return await request;
    } catch (e) {
      console.error(e.response);
      if (e.response.status === 401) {
        AuthenticationGuard.redirectToAuthenticationApp();
      }
      throw e;
    }
  }

  public static async removeAuthorizationAccessToken() {
    return await axios.post(`${this.url}/authorization/accounts/unauthenticate`, {
      headers: this.headers,
    });
  }

  public static async getAccount(accountId: string) {
    return this.requestProxy(
      axios.get(`${this.url}/authorization/accounts/${accountId}`, {
        headers: this.headers,
      })
    );
  }

  public static sendInformationAboutWhereToChangePassword(email: string) {
    return axios.post(
      `${this.url}/authorization/accounts/${email}/send-information-about-where-to-change-password`,
      null,
      {
        headers: this.headers,
      }
    );
  }

  public static requestChangePatientEmailWithoutPassword(
    id: string,
    payload: {
      type: string;
      newEmail: string;
    }
  ) {
    return axios.post(`${this.url}/authorization/accounts/patient/${id}/request-change-email`, payload, {
      headers: this.headers,
    });
  }

  public static activatePatientAccount(accountId: string) {
    return axios.post(`${this.url}/authorization/accounts/patient/${accountId}/activate-account`, {
      headers: this.headers,
    });
  }

  public static get headers() {
    return {
      "Content-Type": "application/json",
    };
  }

  public static async scheduleToSendInsuranceToVerify(patientId: string) {
    return axios.post(`${this.url}/booking/patients/${patientId}/schedule-to-send-insurance-to-verify`, {
      headers: this.headers,
    });
  }
}
