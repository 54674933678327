import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Table, Tabs, Typography } from "antd";
import { ApiClient } from "../../../api-client/api.client";
import { FormInstance } from "antd/es/form";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import moment from "moment/moment";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: boolean;
  isAcuitasSyncInProgress: boolean;
  currentPage: number;
  patients: {
    total: number;
    offset: number;
    limit: number;
    data: any[]; // FIXME
  };
  numberOfFailedSyncPatients: number;
}

export class PatientsListComponent extends React.Component<IProps, IState> {
  private searchForm = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: false,
    isAcuitasSyncInProgress: false,
    currentPage: 1,
    patients: {
      total: 0,
      offset: 0,
      limit: 50,
      data: [],
    },
    numberOfFailedSyncPatients: 0,
  };

  public componentDidMount(): void {
    this.getCountOfFailedSynchronisedPatients();
    this.getPatients();
  }

  public async getPatients() {
    this.setState({ isLoading: true });

    try {
      const searchForm = this.searchForm.current!.getFieldsValue();
      const { data: patients } = await ApiClient.findPatients({
        offset: (this.state.currentPage - 1) * this.state.patients.limit,
        limit: this.state.patients.limit,
        ...(searchForm.firstName?.length && { firstName: searchForm.firstName }),
        ...(searchForm.lastName?.length && { lastName: searchForm.lastName }),
        ...(searchForm.email?.length && { email: searchForm.email }),
        ...(searchForm.birth && { birth: moment(searchForm.birth).format("YYYY-MM-DD") }),
        gender: searchForm.gender,
        phoneNumber: searchForm.phoneNumber,
        ehrId: searchForm.ehrId,
        ...(searchForm.clinicalTrialOptIn !== "all" && {
          questionnaire: {
            clinicalTrialOptIn: searchForm.clinicalTrialOptIn === "true",
          },
        }),
      });

      this.setState({ patients, isLoading: false });
    } catch (e) {
      message.error("Cannot fetch patients data");
      this.setState({ isLoading: false });
    }
  }

  public async getFailedSynchronisedPatients() {
    this.setState({ isLoading: true });

    try {
      const { data: patients } = await ApiClient.findPatients({
        offset: 0,
        limit: "all",
        acuitasSynchronisationFailed: true,
      });

      patients.limit = 50;

      this.setState({ patients, isLoading: false, numberOfFailedSyncPatients: patients.total });
    } catch (e) {
      message.error("Cannot fetch failed synchronised patients data");
      this.setState({ isLoading: false });
    }
  }

  public async getCountOfFailedSynchronisedPatients() {
    try {
      const { data } = await ApiClient.findPatients({
        offset: 0,
        limit: 1,
        acuitasSynchronisationFailed: true,
      });

      this.setState({ numberOfFailedSyncPatients: data.total });
    } catch (e) {}
  }

  public syncPatientToAcuitas = async (id: string) => {
    this.setState({ isAcuitasSyncInProgress: true });
    for (const patient of this.state.patients.data) {
      try {
        await ApiClient.syncPatientToAcuitas(patient.id);
        this.getFailedSynchronisedPatients();
      } catch (e) {
        message.error(`Cannot sync patient: ${patient.id}`);
      }
    }
    this.setState({ isAcuitasSyncInProgress: false });
  };

  public onPaginationChange = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getPatients();
    });
  };

  public onSearchFormFinish = () => {
    this.getPatients();
  };

  public onTabChange = (tab: string) => {
    if (tab === "1") {
      this.getPatients();
    }
    if (tab === "2") {
      this.getFailedSynchronisedPatients();
    }
  };

  public onFormReset = () => {
    this.searchForm.current!.resetFields();
  };

  public render() {
    const { patients, currentPage, isLoading, isAcuitasSyncInProgress, numberOfFailedSyncPatients } = this.state;

    const columns = [
      {
        title: "First name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Phone number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Birth",
        dataIndex: "birth",
        key: "birth",
      },
      {
        title: "Acuitas PID",
        dataIndex: "acuitasPid",
        key: "acuitasPid",
        render: (acuitasPid: string, record: object) => (
          <>
            {acuitasPid}
            <small style={{ display: "block" }}>type: {record.type}</small>
          </>
        ),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => dateFormatter(new Date(date)),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: ({ id }) => {
          return (
            <Link to={`/patients/${id}/edit`}>
              <Button size={"small"}>Show</Button>
            </Link>
          );
        },
      },
    ];

    return (
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={"small"}
        onChange={this.onTabChange}
        className={numberOfFailedSyncPatients > 0 ? "acuitas-failed-sync" : undefined}>
        <Tabs.TabPane tab="Patients" key="1">
          <Row style={{ margin: "10px 0 24px" }}>
            <Col span={24} style={{ backgroundColor: "#F8F8F8", padding: "20px 10px" }}>
              <Form layout="inline" ref={this.searchForm} name="search-form" onFinish={this.onSearchFormFinish}>
                <Form.Item name="firstName" label="First name" style={{ marginBottom: 16 }}>
                  <Input />
                </Form.Item>
                <Form.Item name="lastName" label="Last name" style={{ marginBottom: 16 }}>
                  <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" style={{ marginBottom: 16 }}>
                  <Input />
                </Form.Item>
                <Form.Item name="phoneNumber" label="Phone number" style={{ marginBottom: 16 }}>
                  <Input />
                </Form.Item>
                <Form.Item name="gender" label="gender" initialValue="" style={{ marginBottom: 16 }}>
                  <Select placeholder="Select gender" style={{ width: 130 }}>
                    <Select.Option value={""}>All</Select.Option>
                    <Select.Option value={"male"}>Male</Select.Option>
                    <Select.Option value={"female"}>Female</Select.Option>
                    <Select.Option value={"not_specified"}>Not specified</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="birth" label="Birth" style={{ marginBottom: 16 }}>
                  <DatePicker />
                </Form.Item>
                <Form.Item name="ehrId" label="EHR ID" style={{ marginBottom: 16 }}>
                  <Input />
                </Form.Item>
                <Form.Item
                  initialValue={"all"}
                  name="clinicalTrialOptIn"
                  label="Clinical trial Opt-in"
                  style={{ marginBottom: 16 }}>
                  <Select style={{ width: 130 }}>
                    <Select.Option value={"all"}>All</Select.Option>
                    <Select.Option value={"false"}>No</Select.Option>
                    <Select.Option value={"true"}>Yes</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 16 }}>
                  <div>
                    <Button type="primary" loading={isLoading} htmlType="submit" icon={<SearchOutlined />}>
                      Search
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item style={{ marginBottom: 16 }}>
                  <Button htmlType="button" onClick={this.onFormReset}>
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Link to="/patients/create">
                <Button type="primary">Create patient</Button>
              </Link>
            </Col>
          </Row>
          <Typography.Text style={{ marginBottom: 10, display: "block" }}>
            Total: <strong>{patients.total}</strong>
          </Typography.Text>
          <Table
            bordered
            loading={isLoading}
            size="small"
            dataSource={patients.data}
            columns={columns}
            pagination={{
              pageSize: patients.limit,
              current: currentPage,
              total: patients.total,
              onChange: this.onPaginationChange,
              showSizeChanger: false,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Acuitas - failed synchronisation (${numberOfFailedSyncPatients})`} key="2">
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" loading={isAcuitasSyncInProgress} onClick={this.syncPatientToAcuitas}>
                Sync all patients to Acuitas
              </Button>
            </Col>
          </Row>
          <Typography.Text style={{ marginBottom: 10, display: "block" }}>
            Total: <strong>{patients.total}</strong>
          </Typography.Text>
          <Table
            bordered
            loading={isLoading}
            size="small"
            dataSource={patients.data}
            columns={columns}
            pagination={false}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}
