import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { FormInstance } from "antd/es/form";
import { ApiClient } from "../../../api-client/api.client";
import moment from "moment";
import { states } from "../../../helpers/states.helper";
import { dateFormatter } from "../../../helpers/date-formatter.helper";
import { EthnicityField } from "./ethnicity-field";

interface IProps extends RouteComponentProps {}

interface IState {
  isLoading: {
    patient: boolean;
    syncToAcuitas: boolean;
    matchPatientWithAccount: boolean;
    sendInsuranceToVerify: boolean;
  };
  isShippingAddress: boolean;
  patient: any;
  addAddressDetails: boolean;
  patientType: "routine_optometry" | "life_sciences" | "schools";
}

export class EditPatientFormComponent extends React.Component<IProps, IState> {
  private form = React.createRef<FormInstance>();

  public state: IState = {
    isLoading: { patient: false, syncToAcuitas: false, matchPatientWithAccount: false },
    isShippingAddress: false,
    patient: undefined,
    addAddressDetails: false,
    patientType: "routine_optometry",
  };

  public componentDidMount(): void {
    this.getPatient();
  }

  public redirectOnSave() {
    const params = new URLSearchParams(this.props.location.search);
    const redirectTo = params.get("redirectTo");
    if (redirectTo === "patient-tracker") {
      this.props.history.push({
        pathname: `/${redirectTo}`,
        search: `?trackerId=${params.get("trackerId")}`,
      });
    }
  }

  public async getPatient() {
    try {
      this.setState({
        isLoading: { ...this.state.isLoading, patient: true },
      });
      const { id } = this.props.match.params;
      const { data: patient } = await ApiClient.getPatient(id);

      this.setState({
        isLoading: { ...this.state.isLoading, patient: false },
        patient,
        addAddressDetails: !!patient.addresses.length,
        patientType: patient.type,
      });

      if (patient?.addresses.find((x) => x.type === "shipping")) {
        this.setState({ isShippingAddress: true });
      }
    } catch {
      message.error("Patient cannot be fetched!");
    }
  }

  private onFinish = async (values: any) => {
    const { isShippingAddress, addAddressDetails, patientType } = this.state;
    this.setState({
      isLoading: { ...this.state.isLoading, patient: true },
    });

    try {
      const { id } = this.props.match.params;

      const body = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
        gender: values.gender,
        birth: moment(values.birth).format("YYYY-MM-DD"),
        phoneNumber: values.phoneNumber,
        address: {
          type: "billing",
          city: values.billingAddressCity,
          street: values.billingAddressStreet,
          state: values.billingAddressState,
          zipCode: values.billingAddressZipCode,
        },
        ethnicity: values.ethnicity,
        ...(patientType === "life_sciences"
          ? {
              caregiver: values.caregiver,
              caregiverRelationship: values.caregiverRelationship,
              consent: values.consent === "true",
              language: values.language,
            }
          : { notes: values.notes, ehrId: values.ehrId }),
      };

      if (isShippingAddress) {
        body.address = {
          type: "shipping",
          city: values.shippingAddressCity,
          street: values.shippingAddressStreet,
          state: values.shippingAddressState,
          zipCode: values.shippingAddressZipCode,
        };
      }

      if (!addAddressDetails) {
        delete body.address;
      }

      if (patientType === "life_sciences") {
        await ApiClient.updateLifeSciencePatient(id, body);
      } else if (patientType === "schools") {
        await ApiClient.updateSchoolPatient(id, body);
      } else {
        await ApiClient.updatePatient(id, body);
      }

      message.success("Patient successfully saved!");

      this.redirectOnSave();
      this.getPatient();
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
      this.setState({
        isLoading: { ...this.state.isLoading, patient: true },
      });
    }
  };

  public async syncPatientToAcuitas(id: string) {
    this.setState({
      isLoading: { ...this.state.isLoading, syncToAcuitas: true },
    });
    try {
      await ApiClient.syncPatientToAcuitas(id);
      this.getPatient();

      this.setState({
        isLoading: { ...this.state.isLoading, syncToAcuitas: false },
      });
    } catch (e) {
      message.error("Cannot synchronize data with Acuitas");
      this.setState({
        isLoading: { ...this.state.isLoading, syncToAcuitas: false },
      });
    }
  }

  public async matchPatientWithAccount(id: string) {
    this.setState({
      isLoading: { ...this.state.isLoading, matchPatientWithAccount: true },
    });
    try {
      await ApiClient.matchPatientWithAccount(id);
      this.getPatient();

      this.setState({
        isLoading: { ...this.state.isLoading, matchPatientWithAccount: false },
      });
    } catch (e) {
      notification.error({
        message: `Matching error`,
        description: e.response.data.message,
        placement: "bottomLeft",
      });
      this.setState({
        isLoading: { ...this.state.isLoading, matchPatientWithAccount: false },
      });
    }
  }

  private scheduleToSendInsuranceToVerify = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: { ...this.state.isLoading, sendInsuranceToVerify: true } });
    try {
      await ApiClient.scheduleToSendInsuranceToVerify(id);
      message.success("Insurance verification scheduled");
    } catch (e) {
      message.error(e?.response?.data?.message || "Unknown Error");
    } finally {
      this.setState({ isLoading: { ...this.state.isLoading, sendInsuranceToVerify: false } });
    }
  };

  public render() {
    const { isLoading, patient, isShippingAddress, addAddressDetails, patientType } = this.state;
    const billingAddress = patient?.addresses.find((x) => x.type === "billing");
    const shippingAddress = patient?.addresses.find((x) => x.type === "shipping");
    return (
      <>
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <Card
              title="Patient"
              loading={isLoading.patient}
              extra={
                <Button type="primary">
                  <Link
                    to={{
                      pathname: `/appointments`,
                      search: `?email=${patient?.email}&amount=all`,
                    }}>
                    Show all appointments
                  </Link>
                </Button>
              }>
              {patient?.syncToAcuitasFailedAt && (
                <Alert
                  message="Acuitas notification"
                  description={
                    <div>
                      There was an error while syncing data to Acuitas{" "}
                      {dateFormatter(new Date(patient?.syncToAcuitasFailedAt), "UTC")}
                      <br />
                      <Button
                        type="primary"
                        size="small"
                        loading={isLoading.syncToAcuitas}
                        onClick={() => this.syncPatientToAcuitas(patient?.id)}>
                        Synchronize patient data to Acuitas
                      </Button>
                    </div>
                  }
                  type="warning"
                  showIcon
                  closable={false}
                  style={{ marginBottom: "15px" }}
                />
              )}
              <Form layout="vertical" ref={this.form} name="update-patient" onFinish={this.onFinish}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[{ required: true }]}
                  initialValue={patient?.firstName}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  rules={[{ required: true }]}
                  initialValue={patient?.lastName}>
                  <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true }]} initialValue={patient?.email}>
                  <Input />
                </Form.Item>
                <Form.Item name="gender" label="Gender" rules={[{ required: true }]} initialValue={patient?.gender}>
                  <Select placeholder="Select gender">
                    <Select.Option value={"male"}>Male</Select.Option>
                    <Select.Option value={"female"}>Female</Select.Option>
                    <Select.Option value={"not_specified"}>Not specified</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="birth"
                  label="Birth"
                  rules={[{ required: true }]}
                  initialValue={patient && patient.birth ? moment(patient.birth, "YYYY-MM-DD") : undefined}>
                  <DatePicker format="l" />
                </Form.Item>
                <Form.Item
                  extra="Country code + 10 digits"
                  name="phoneNumber"
                  label="Phone number"
                  rules={[
                    { required: true },
                    {
                      min: 11,
                      max: 11,
                      message: "Phone number must have 11 digits.",
                    },
                  ]}
                  initialValue={patient?.phoneNumber}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Ethnicity"
                  name="ethnicity"
                  initialValue={patient?.ethnicity}
                  rules={[
                    {
                      validator: (rule, value, cb) => {
                        if (String(value || "").includes("other")) {
                          const otherValue = RegExp(/other\((.*)\)/).exec(value)?.[1];
                          cb(!otherValue ? 'Description the "other" ethnicity is required' : undefined);
                          return;
                        }
                        cb();
                      },
                    },
                  ]}>
                  <EthnicityField />
                </Form.Item>

                {patientType === "routine_optometry" && (
                  <>
                    <Form.Item name="notes" label="Notes" initialValue={patient?.notes}>
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="ehrId" label="EHR ID" initialValue={patient?.ehrId}>
                      <Input />
                    </Form.Item>
                  </>
                )}

                {patientType === "life_sciences" && (
                  <>
                    <Form.Item
                      name="caregiver"
                      label="Caregiver"
                      rules={[{ required: true }]}
                      initialValue={patient?.caregiver}>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="caregiverRelationship"
                      label="Caregiver Relationship"
                      rules={[{ required: true }]}
                      initialValue={patient?.caregiverRelationship}>
                      <Select placeholder="Select gender">
                        <Select.Option value="parent">Parent</Select.Option>
                        <Select.Option value="guardian">Guardian</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="language"
                      label="Language"
                      rules={[{ required: true }]}
                      initialValue={patient?.language}>
                      <Select placeholder="Select gender">
                        <Select.Option value="english">English</Select.Option>
                        <Select.Option value="spanish">Español</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="consent"
                      label="I/MY CHILD HAS BEEN PRESCRIBED ONE OF THE FOLLOWING MEDICATIONS: KALYDECO®, ORKAMBI®, SYMDEKO®, OR TRIKAFTA®"
                      rules={[{ required: true, message: "Please pick consent!" }]}
                      initialValue={!!patient?.consent}>
                      <Space direction={"vertical"}>
                        The patient is currently prescribed one of these medications in accordance with the prescribed
                        medicine's age and prescription label diagnosis requirements
                        <Radio.Group defaultValue={!!patient?.consent}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Space>
                    </Form.Item>
                  </>
                )}
                <Form.Item name="acuitasPid" label="ACUITAS PID" initialValue={patient?.acuitasPid}>
                  <Input disabled />
                </Form.Item>
                <Form.Item name="accountEmail" label="Patient Portal email">
                  <Input value={patient?.accountEmail} disabled />
                  {!patient?.accountEmail && (
                    <Button
                      type="link"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      loading={isLoading.matchPatientWithAccount}
                      onClick={() => this.matchPatientWithAccount(patient?.id)}>
                      Find associated Patient Portal account
                    </Button>
                  )}
                </Form.Item>
                <Form.Item
                  name="addAddressDetails"
                  valuePropName="checked"
                  initialValue={addAddressDetails}
                  style={{ marginBottom: 5 }}>
                  <Checkbox onChange={(e) => this.setState({ addAddressDetails: e.target.checked })}>
                    Add address details
                  </Checkbox>
                </Form.Item>

                {addAddressDetails ? (
                  <>
                    <Form.Item
                      name="billingAddressStreet"
                      label="Billing address street"
                      rules={[{ required: true }]}
                      initialValue={billingAddress?.street}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="billingAddressCity"
                      label="Billing address city"
                      rules={[{ required: true }]}
                      initialValue={billingAddress?.city}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="billingAddressState"
                      label="Billing address state"
                      rules={[{ required: true }]}
                      initialValue={billingAddress?.state}>
                      <Select placeholder="Select state">
                        {states.map((state) => (
                          <Select.Option key={state} value={state}>
                            {state}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="billingAddressZipCode"
                      label="Billing address zip code"
                      rules={[
                        { required: true },
                        {
                          min: 5,
                          max: 5,
                          message: "Zip code must have 5 digits.",
                        },
                      ]}
                      initialValue={billingAddress?.zipCode}>
                      <Input />
                    </Form.Item>
                    {!shippingAddress ? (
                      <Checkbox
                        checked={!isShippingAddress}
                        onChange={() =>
                          this.setState({
                            isShippingAddress: !isShippingAddress,
                          })
                        }
                        style={{ marginBottom: 24 }}>
                        Shipping address same as billing?
                      </Checkbox>
                    ) : null}
                    {isShippingAddress || shippingAddress ? (
                      <>
                        <Form.Item
                          name="shippingAddressStreet"
                          label="Shipping address street"
                          initialValue={shippingAddress?.street}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="shippingAddressCity"
                          label="Shipping address city"
                          initialValue={shippingAddress?.city}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="shippingAddressZipCode"
                          label="Shipping address zip code"
                          rules={[
                            {
                              min: 5,
                              max: 5,
                              message: "Zip code must have 5 digits.",
                            },
                          ]}
                          initialValue={shippingAddress?.zipCode}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="shippingAddressState"
                          label="Shipping address state"
                          initialValue={shippingAddress?.state}>
                          <Select placeholder="Select state">
                            {states.map((state) => (
                              <Select.Option key={state} value={state}>
                                {state}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    ) : null}
                  </>
                ) : null}

                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button loading={isLoading.patient} type="primary" htmlType="submit" disabled={isLoading.patient}>
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
            <br />
            <Card title="Insurance">
              <Form layout="inline">
                <Form.Item label="Last verified date of insurance (pVerify)">
                  <Input disabled value={patient?.lastVerifiedDateOfInsurance || ""} />
                </Form.Item>

                <Button
                  loading={isLoading.sendInsuranceToVerify}
                  type="danger"
                  disabled={isLoading.sendInsuranceToVerify}
                  onClick={this.scheduleToSendInsuranceToVerify}>
                  Schedule to verify
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
